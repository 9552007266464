import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage() {
  return (
    <Layout>
      <SEO
        title="Contact"
      />
      <section>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSexMXCqBsJMgRkydFe9wSIzPVjo1fFkmPXNcttM_eGyB1t6GA/viewform?embedded=true" width="100%" height="600" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
      </section>
    </Layout>
  );
}

export default ContactPage;
